<template>
  <div class="pt-4 pb-4 flex space-x-4">
    <div
      class="flex-none rounded-lg object-cover  overflow-hidden w-20 h-20"
      :class="{ 'bg-gray-100': !book.thumbnailUrl }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-10 w-10 m-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        v-if="!book.thumbnailUrl"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
        />
      </svg>
      <img
        :src="book.thumbnailUrl.replace('http:', 'https:')"
        v-if="book.thumbnailUrl"
        class="rounded-lg"
      />
    </div>
    <div>
      <h2 className="text-lg font-semibold text-black">
        {{ book.title }}
      </h2>
      <div class="italic inline">by</div>
      {{ " " }}
      <div
        class="inline text-black"
        v-for="(author, index) in book.authors"
        :key="author.name"
      >
        {{ author.name }}<span v-if="index + 1 < book.authors.length">, </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BookItem",
  props: ["book"]
});
</script>
