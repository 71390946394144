
import axios from "axios";
import Cookies from "js-cookie";
import { State } from "@/interfaces";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "LogIn",
  data() {
    return {
      error: "",
      username: "",
      password: ""
    };
  },
  computed: mapState<State>({
    authenticated: "authenticated"
  }),
  methods: {
    async logOut() {
      try {
        await axios.post(
          "/api/ui-log-out/",
          {},
          {
            withCredentials: true,
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
              "Content-Type": "application/json"
            }
          }
        );
        this.$store.dispatch("setAuthenticated", false);
        this.$router.push("/");
      } catch (error) {
        this.error = "Could not log out";
      }
    },
    async logIn() {
      const { username, password } = this;

      if (!username || !password) {
        this.error = "Please enter username and password";
        return;
      }

      await axios.get("/api/set-csrf-cookie/");

      try {
        await axios.post(
          "/api/ui-log-in/",
          { username, password },
          {
            withCredentials: true,
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
              "Content-Type": "application/json"
            }
          }
        );
        this.$store.dispatch("setAuthenticated", true);
        var urlParams = new URLSearchParams(window.location.search);
        const next = urlParams.get("next");
        if (next && next !== "") {
          this.$router.push(`/${urlParams.get("next")?.slice(1)}`);
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          this.error = `Could not log in: ${error.response.data.detail}`;
        } else {
          this.error = `Could not log in: ${error.message}`;
        }

        this.$store.dispatch("setAuthenticated", false);
      }
    }
  }
});
