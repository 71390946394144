<template>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap"
    rel="stylesheet"
  />
  <div class="mx-auto max-w-3xl">
    <div id="header">
      <router-link to="/">
        <p class="text-5xl mt-5" id="graces-books">Grace's Books</p>
      </router-link>
    </div>
    <router-link to="/log-in"
      ><span class="text-3xl mr-6" id="log-in">👨‍👩‍👧</span></router-link
    >
    <router-link to="/add-books" v-if="authenticated"
      ><span class="text-3xl mr-6" id="log-in">📚</span></router-link
    >
    <div class="mt-12 m-3 flex-grow bg-gray-50 rounded-xl p-8 md:p-8">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import { State } from "@/interfaces";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  async created() {
    this.$store.dispatch("checkAuthenticationStatus");
  },
  computed: mapState<State>({
    authenticated: "authenticated"
  }),
  methods: {
    loadBooks(force = false) {
      if (this.$store.state.books.length === 0 || force) {
        this.$store.dispatch("loadBooks", {
          clearBooks: true,
          next: `/api/books/?format=json`
        });
      }
    }
  }
});
</script>

<style>
#graces-books {
  font-family: "Pacifico", cursive;
  color: hsl(273, 93%, 36%);
  transform: rotate(-5deg);
  display: block;
}

#header {
  text-align: center;
}

#log-in {
  float: right;
}
</style>
