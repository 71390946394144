
import { defineComponent } from "vue";
import { getDefaultScanner, scanImageData } from "zbar.wasm";
import { ZBarConfigType, ZBarSymbolType } from "zbar.wasm/dist/enum";
import LoadingSpinner from "./LoadingSpinner.vue";

interface Scanner {
  cameras: MediaDeviceInfo[];
  foundBarcode: boolean;
  isLoading: boolean;
  isCameraReady: boolean;
  message: string;
  stream: MediaStream;
}

async function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export default defineComponent({
  name: "BarcodeScanner",
  emits: ["scannedBarcode", "stopScanner"],
  components: {
    LoadingSpinner
  },
  props: ["scanningPaused"],
  data() {
    return {
      scanner: {
        cameras: [],
        foundBarcode: false,
        isLoading: true,
        isCameraReady: false,
        message: "",
        stream: new MediaStream()
      } as Scanner
    };
  },
  methods: {
    stopCamera() {
      this.$emit("stopScanner");
    },
    async startScanning(s: MediaStream) {
      {
        this.scanner.stream.getTracks().forEach(t => t.stop());

        this.scanner.stream = s;

        let video = this.$refs.video as HTMLVideoElement;
        video.srcObject = this.scanner.stream;

        this.scanner.isCameraReady = true;
        this.scanner.isLoading = false;

        const scanner = await getDefaultScanner();
        scanner.setConfig(
          ZBarSymbolType.ZBAR_EAN13,
          ZBarConfigType.ZBAR_CFG_UNCERTAINTY,
          1
        );

        const canvas = this.$refs.videoCanvas as HTMLCanvasElement;
        const ctx = canvas.getContext("2d");
        const canvasWidth = 400;
        const canvasHeight = 200;

        while (!this.scanner.foundBarcode) {
          await wait(100);

          if (this.scanningPaused) {
            continue;
          }

          if (ctx !== null && video.videoWidth > 0) {
            video.play();
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            ctx.drawImage(
              video,
              Math.abs(canvasWidth - videoWidth) / 2,
              Math.abs(canvasHeight - videoHeight) / 2,
              canvasWidth,
              canvasHeight,
              0,
              0,
              canvasWidth,
              canvasHeight
            );
            const img = ctx.getImageData(0, 0, canvasWidth, canvasHeight);
            let result = await scanImageData(img, scanner);
            for (let j = 0; j < result.length; j++) {
              let barcode = result[j].decode();

              if (result[j].typeName === "ZBAR_EAN13") {
                this.scanner.foundBarcode = true;
                this.$emit("scannedBarcode", barcode);
                await wait(1000);
                this.scanner.foundBarcode = false;
              }
            }
          }
        }
      }
    },
    changeCamera(event: Event) {
      let select = event.target as HTMLSelectElement;
      let deviceId = select.options[select.selectedIndex].value;
      const constraints = {
        video: { deviceId: { exact: deviceId } },
        audio: false
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(s => {
          this.$store.dispatch("setLastDeviceId", deviceId);
          this.startScanning(s);
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  mounted: function() {
    // Set focus on modal so we can listen for esc key
    let scannerModal = this.$refs.scannerModal as HTMLElement;
    scannerModal.focus();
  },
  unmounted: function() {
    this.scanner.stream.getTracks().forEach(t => t.stop());
  },
  async created() {
    try {
      let devices = await navigator.mediaDevices.enumerateDevices();
      this.scanner.cameras = devices.filter(d => d.kind == "videoinput");
    } catch (error) {
      console.error(error);
    }

    let constraints = {
      audio: false,
      video: {
        facingMode: "environment",
        aspectRatio: 1
      }
    } as any;

    if (this.$store.state.lastDeviceId) {
      constraints.video = {
        deviceId: { exact: this.$store.state.lastDeviceId }
      } as any;
    }

    await navigator.mediaDevices
      .getUserMedia(constraints)
      .then(s => this.startScanning(s))
      .catch(e => {
        this.scanner.isLoading = false;
        this.scanner.message =
          e.message.length > 50
            ? `${e.message.substring(0, 50)}...`
            : e.message;
      });
  }
});
