import _ from "lodash";
import axios from "axios";
import Cookies from "js-cookie";
import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import {
  ADD_BOOKS,
  CLEAR_BOOKS,
  SET_AUTHENTICATED,
  SET_BOOKS_LOADING,
  SET_BOOK_FILTER,
  SET_BOOK_LOADING_ERROR,
  SET_LAST_DEVICE_ID
} from "./mutation-types";
import { Book, State } from "../interfaces";

export const key: InjectionKey<Store<State>> = Symbol();

const setBookFilter = _.debounce(({ commit }, bookFilter: string) => {
  commit(SET_BOOK_FILTER, bookFilter);
}, 100);

export const store = createStore<State>({
  strict: process.env.NODE_ENV !== "production",
  state: {
    books: [],
    bookFilter: "",
    booksLoading: false,
    bookLoadingError: null,
    lastDeviceId: null,
    authenticated: false
  },
  mutations: {
    [SET_BOOK_FILTER](state, bookFilter: string) {
      state.bookFilter = bookFilter;
    },
    [CLEAR_BOOKS](state) {
      state.books = [];
    },
    [ADD_BOOKS](state, books: Book[]) {
      state.books.push(...books);
    },
    [SET_BOOKS_LOADING](state, booksLoading: boolean) {
      state.booksLoading = booksLoading;
    },
    [SET_BOOK_LOADING_ERROR](state, bookLoadingError: string) {
      state.bookLoadingError = bookLoadingError;
    },
    [ADD_BOOKS](state, books: Book[]) {
      state.books.push(...books);
    },
    [SET_LAST_DEVICE_ID](state, lastDeviceId: string) {
      state.lastDeviceId = lastDeviceId;
    },
    [SET_AUTHENTICATED](state, authenticated: boolean) {
      state.authenticated = authenticated;
    }
  },
  getters: {
    filteredBooks(state) {
      console.log(state.bookFilter);
      console.log(state.books);
      if (state.bookFilter === "") {
        return state.books;
      }
      const filter = state.bookFilter.toLowerCase();
      return state.books.filter(
        book =>
          book.authors.some(a => a.name.toLowerCase().includes(filter)) ||
          book.title.toLowerCase().includes(filter) ||
          book.isbn?.toLowerCase().includes(filter)
      );
    }
  },
  actions: {
    setAuthenticated({ commit }, authenticated: boolean) {
      commit(SET_AUTHENTICATED, authenticated);
    },
    setBookFilter,
    addBook({ commit }, book: Book) {
      commit(ADD_BOOKS, [book]);
    },
    loadBooks({ dispatch, commit }, payload) {
      commit(SET_BOOK_LOADING_ERROR, "");

      if (payload.clearBooks) {
        commit(SET_BOOKS_LOADING, true);
        commit(CLEAR_BOOKS);
      }

      axios
        .get(payload.next)
        .then(r => r.data)
        .then(async r => {
          commit(ADD_BOOKS, r.results);
          if (r.next) {
            dispatch("loadBooks", { clearBooks: false, next: r.next });
          } else {
            commit(SET_BOOKS_LOADING, false);
          }
        })
        .catch(error => {
          let message = "Could not load books: ";
          if (error.response) {
            message += `(response code ${error.response.status})`;
          } else if (error.request) {
            message += "no response from server";
          } else {
            message += error.message;
          }
          commit(SET_BOOK_LOADING_ERROR, message);
        });
    },
    setLastDeviceId({ commit }, lastDeviceId: string) {
      commit(SET_LAST_DEVICE_ID, lastDeviceId);
    },
    async checkAuthenticationStatus({ commit }) {
      await axios
        .get("/api/check-logged-in/", {
          withCredentials: true,
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
            "Content-Type": "application/json"
          }
        })
        .then(r => r.data)
        .then(r => {
          const status = r.details;
          console.debug(`Authentication status: ${status}`);
          commit(SET_AUTHENTICATED, status);
        })
        .catch(error => {
          console.error(error);
          console.debug("Setting authenticated to false");
          commit(SET_AUTHENTICATED, false);
        });
    }
  }
});
