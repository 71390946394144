
import { mapState } from "vuex";
import { State } from "@/interfaces";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  async created() {
    this.$store.dispatch("checkAuthenticationStatus");
  },
  computed: mapState<State>({
    authenticated: "authenticated"
  }),
  methods: {
    loadBooks(force = false) {
      if (this.$store.state.books.length === 0 || force) {
        this.$store.dispatch("loadBooks", {
          clearBooks: true,
          next: `/api/books/?format=json`
        });
      }
    }
  }
});
