<template>
  <div>
    <h2 class="mt-5 mb-3 ml-1 text-2xl font-semibold text-black">
      Mom and Dad Log In
    </h2>
    <div
      v-if="error !== ''"
      class="w-full bg-red-50 rounded text-lg font-semibold text-red-800 px-3 py-3"
    >
      {{ error }}
    </div>
    <div v-if="!authenticated">
      <div>
        <form @submit.prevent="logIn">
          <label class="block">
            <input
              type="text"
              name="Username"
              placeholder="Username"
              v-model="username"
              autocomplete="username"
              autocapitalize="none"
              class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            />
          </label>
          <label class="block">
            <input
              type="password"
              name="Password"
              placeholder="Password"
              v-model="password"
              autocomplete="current-password"
              class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
            />
          </label>
          <button
            class="flex items-center justify-center rounded-md border border-gray-300 p-2 mt-5"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
    <div v-if="authenticated">
      <h2 class="mt-5 mb-3 ml-1 text-lg font-semibold text-green-700">
        You are logged in
      </h2>
      <button
        class="flex items-center justify-center rounded-md border border-gray-300 p-2 mt-5"
        @click="logOut"
      >
        Log Out 🔒
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import Cookies from "js-cookie";
import { State } from "@/interfaces";
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "LogIn",
  data() {
    return {
      error: "",
      username: "",
      password: ""
    };
  },
  computed: mapState<State>({
    authenticated: "authenticated"
  }),
  methods: {
    async logOut() {
      try {
        await axios.post(
          "/api/ui-log-out/",
          {},
          {
            withCredentials: true,
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
              "Content-Type": "application/json"
            }
          }
        );
        this.$store.dispatch("setAuthenticated", false);
        this.$router.push("/");
      } catch (error) {
        this.error = "Could not log out";
      }
    },
    async logIn() {
      const { username, password } = this;

      if (!username || !password) {
        this.error = "Please enter username and password";
        return;
      }

      await axios.get("/api/set-csrf-cookie/");

      try {
        await axios.post(
          "/api/ui-log-in/",
          { username, password },
          {
            withCredentials: true,
            headers: {
              "X-CSRFTOKEN": Cookies.get("csrftoken"),
              "Content-Type": "application/json"
            }
          }
        );
        this.$store.dispatch("setAuthenticated", true);
        var urlParams = new URLSearchParams(window.location.search);
        const next = urlParams.get("next");
        if (next && next !== "") {
          this.$router.push(`/${urlParams.get("next")?.slice(1)}`);
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          this.error = `Could not log in: ${error.response.data.detail}`;
        } else {
          this.error = `Could not log in: ${error.message}`;
        }

        this.$store.dispatch("setAuthenticated", false);
      }
    }
  }
});
</script>
