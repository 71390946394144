
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { State } from "@/interfaces";
import BarcodeScanner from "./BarcodeScanner.vue";
import BookItem from "./BookItem.vue";
import LoadingSpinner from "./LoadingSpinner.vue";

export default defineComponent({
  name: "BookList",
  components: {
    BookItem,
    LoadingSpinner,
    BarcodeScanner
  },
  data() {
    return {
      searchText: "",
      scannerEnabled: false
    };
  },
  computed: mapState<State>({
    ...mapGetters(["filteredBooks"]),
    books: "books",
    booksLoading: "booksLoading",
    bookLoadingError: "bookLoadingError"
  }),
  async created() {
    this.searchText = this.$store.state.bookFilter;
    this.loadBooks();
  },
  methods: {
    loadBooks(force = false) {
      if (this.$store.state.books.length === 0 || force) {
        this.$store.dispatch("loadBooks", {
          clearBooks: true,
          next: `/api/books/?format=json`
        });
      }
    },
    search() {
      this.$store.dispatch("setBookFilter", this.searchText);
    },
    onSannedBarcode(barcode: string) {
      this.searchText = barcode;
      this.$store.dispatch("setBookFilter", this.searchText);
      this.onStopScanner();
    },
    onStopScanner() {
      this.scannerEnabled = false;
    }
  }
});
