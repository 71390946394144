<template>
  <div ref="addBookComponent">
    <h2 class="mt-5 mb-3 ml-1 text-2xl font-semibold text-black">
      Add Books
    </h2>

    <h2
      class="w-full bg-red-50 rounded text-lg font-semibold text-red-800 px-3 py-3"
      v-if="addBookData.errorMessage"
    >
      {{ addBookData.errorMessage }}
    </h2>

    <h2
      class="w-full bg-green-50 rounded text-lg font-semibold text-green-800 px-3 py-3"
      v-if="addBookData.successMessage"
    >
      {{ addBookData.successMessage }}
    </h2>

    <h2 class="mt-5 mb-3 ml-1 text-lg font-semibold text-black">
      Add Via Scanner
    </h2>
    <BarcodeScanner
      v-if="addBookData.scannerEnabled"
      @scanned-barcode="onSannedBarcode"
      @stop-scanner="onStopScanner"
      :scanningPaused="addBookData.showAddModal"
    />
    <div @click="showScanner" class="ml-1">
      <img src="../assets/barcode.svg" width="45" alt="Scan barcode" />
    </div>

    <h2 class="mt-5 mb-3 ml-1 text-lg font-semibold text-black">
      Add Via Search
    </h2>
    <TitleSearch
      @search-completed="titleSearchCompleted"
      @search-errored="titleSearchErrored"
    />

    <h2 class="mt-5 mb-3 ml-1 text-lg font-semibold text-black">
      Add Manually
    </h2>
    <form @submit.prevent="manualAddBook">
      <label class="block">
        <input
          type="text"
          v-model="addBookData.title"
          placeholder="Book title"
          required
          class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </label>

      <label class="block">
        <input
          type="text"
          v-model="addBookData.authors"
          placeholder="Authors (comma separated)"
          required
          class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </label>
      <label class="block">
        <input
          type="text"
          v-model="addBookData.isbn"
          placeholder="ISBN"
          class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </label>
      <label class="block">
        <input
          type="text"
          v-model="addBookData.thumbnailUrl"
          placeholder="Thumbnail URL"
          class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </label>
      <label class="block">
        <input
          type="text"
          v-model="addBookData.description"
          placeholder="Description"
          class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </label>
      <button
        class="flex items-center justify-center rounded-md border border-gray-300 p-2 mt-5"
      >
        Add Book
      </button>
    </form>
    <div
      id="add-book-modal"
      v-show="addBookData.showAddModal"
      tabindex="1"
      ref="addBookModal"
      @keyup.esc="closeAddModal"
    >
      <div class="flex flex-col mt-10 w-4/5 md:w-1/2">
        <h2 class="mt-5 mb-3 ml-1 text-lg font-semibold text-black">
          Click book to add
        </h2>
        <h2
          class="w-full bg-red-50 rounded text-lg font-semibold text-red-800 px-3 py-3"
          v-if="addBookData.errorMessage"
        >
          {{ addBookData.errorMessage }}
        </h2>

        <h2
          class="w-full bg-green-50 rounded text-lg font-semibold text-green-800 px-3 py-3"
          v-if="addBookData.successMessage"
        >
          {{ addBookData.successMessage }}
        </h2>

        <ul class="mt-5">
          <li
            v-for="book in addBookData.foundBooks"
            :key="book.title + book.authors.join('-')"
            class="block py-2 rounded-md"
          >
            <BookItem :book="book" @click="scanAddBook(book)" />
          </li>
        </ul>
        <button
          id="close-add-modal-button"
          class="flex items-center justify-center rounded-md border border-gray-300 p-2 mt-5 w-20 mx-auto"
          @click="closeAddModal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import Cookies from "js-cookie";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { Book, State } from "@/interfaces";
import BarcodeScanner from "./BarcodeScanner.vue";
import BookItem from "./BookItem.vue";
import TitleSearch from "./TitleSearch.vue";

interface AddBookData {
  scannerEnabled: boolean;
  title: string;
  authors: string;
  isbn: string;
  thumbnailUrl: string;
  description: string;
  foundBooks: Book[];
  errorMessage: string;
  successMessage: string;
  showAddModal: boolean;
}

export default defineComponent({
  name: "BookList",
  components: {
    BarcodeScanner,
    BookItem,
    TitleSearch
  },
  mounted: function() {
    // Set focus on modal so we can listen for esc key
    let addBookModal = this.$refs.addBookModal as HTMLElement;
    addBookModal.focus();
  },
  data() {
    return {
      addBookData: {
        scannerEnabled: false,
        title: "",
        authors: "",
        isbn: "",
        thumbnailUrl: "",
        description: "",
        foundBooks: [],
        errorMessage: "",
        successMessage: "",
        showAddModal: false
      } as AddBookData
    };
  },
  computed: mapState<State>({
    authenticated: "authenticated"
  }),
  methods: {
    showAddModal() {
      this.addBookData.showAddModal = true;
    },
    titleSearchCompleted(foundBooks: Book[]) {
      this.clearMessages();
      this.addBookData.foundBooks = foundBooks;
      this.showAddModal();
    },
    titleSearchErrored(errorMessage: string) {
      this.clearMessages();
      this.addBookData.errorMessage = errorMessage;
    },
    clearMessages() {
      this.addBookData.errorMessage = "";
      this.addBookData.successMessage = "";
    },
    showScanner() {
      this.addBookData.scannerEnabled = true;
      this.clearMessages();
    },
    closeAddModal() {
      let addBookModal = this.$refs.addBookModal as HTMLElement;
      addBookModal.scrollTo(0, 0);
      this.addBookData.showAddModal = false;
      this.clearMessages();
    },
    onSannedBarcode(barcode: string) {
      this.clearMessages();
      let url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${barcode}`;
      axios
        .get(url)
        .then(r => r.data)
        .then(r => {
          if (r.totalItems === 0) {
            this.addBookData.errorMessage =
              "Could not find any matching books.";
            this.addBookData.foundBooks = [];
          } else {
            this.addBookData.foundBooks = [];
            for (var i of r.items) {
              this.addBookData.foundBooks.push({
                title: i.volumeInfo.title,
                authors:
                  i.volumeInfo.authors !== undefined
                    ? i.volumeInfo.authors.map((a: string) => {
                        return { name: a };
                      })
                    : [{ name: "Unknown" }],
                description: i.volumeInfo.description,
                isbn: barcode,
                thumbnailUrl: i.volumeInfo.imageLinks?.thumbnail
              });
            }
          }
          this.showAddModal();
        })
        .catch(error => {
          console.log(error);
          this.addBookData.errorMessage = "Could not load book info";
          this.showAddModal();
        });
    },
    onStopScanner() {
      this.addBookData.scannerEnabled = false;
    },
    clearForm() {
      this.addBookData.title = "";
      this.addBookData.description = "";
      this.addBookData.isbn = "";
      this.addBookData.thumbnailUrl = "";
      this.addBookData.authors = "";
    },
    async manualAddBook() {
      let result = await this.addBook({
        title: this.addBookData.title,
        description: this.addBookData.description,
        isbn: this.addBookData.isbn,
        thumbnailUrl: this.addBookData.thumbnailUrl,
        authors: this.addBookData.authors.split(",").map(a => {
          return { name: a };
        })
      });

      if (result) {
        this.clearForm();
      }
    },
    async scanAddBook(book: Book) {
      await this.addBook(book);
    },
    async addBook(book: Book): Promise<boolean> {
      try {
        await axios.post("/api/books/", book, {
          withCredentials: true,
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
            "Content-Type": "application/json"
          }
        });
        this.clearMessages();
        this.$store.dispatch("addBook", book);
        this.addBookData.successMessage = "Book added 💫";
        return true;
      } catch (error) {
        this.clearMessages();
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.detail) {
              this.addBookData.errorMessage = `Could not add book: ${error.response.data.detail}`;
            }
            if (error.response.data.nonFieldErrors) {
              this.addBookData.errorMessage = `Could not add book: Book already added`;
            }
          } else {
            this.addBookData.errorMessage = `Could not add book: ${error.message}`;
          }
        } else {
          this.addBookData.errorMessage = "Could not add book";
        }
        return false;
      }
    }
  }
});
</script>

<style scoped>
#add-book-modal {
  min-width: 310px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 100;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 25px;
}

#add-book-modal::-webkit-scrollbar {
  display: none;
}

#close-add-modal-button {
  position: fixed;
  bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
}
</style>
