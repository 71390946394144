import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import App from "./App.vue";
import AddBook from "./components/AddBook.vue";
import BookList from "./components/BookList.vue";
import LogIn from "./components/LogIn.vue";
import { store, key } from "./store";

import "./assets/site.css";

const routes = [
  { path: "/", component: BookList },
  { path: "/log-in", component: LogIn },
  {
    path: "/add-books",
    component: AddBook,
    meta: {
      requiresAuth: true
    }
  }
];

store.dispatch("checkAuthenticationStatus").then(() => {
  const router = createRouter({
    history: createWebHistory(),
    routes
  });

  router.beforeEach((to, from, next) => {
    console.log(store.state);
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    console.log(store.state.authenticated);
    console.log(requiresAuth && !store.state.authenticated);
    if (requiresAuth && !store.state.authenticated)
      next({
        path: "log-in",
        query: {
          next: to.fullPath
        }
      });
    else next();
  });

  createApp(App)
    .use(store, key)
    .use(router)
    .mount("#app");
});
