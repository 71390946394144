<template>
  <div>
    <form @submit.prevent="searchForBook">
      <label class="block">
        <input
          type="text"
          v-model="searchText"
          placeholder="Book title"
          required
          class="w-60 mt-1 block rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
      </label>
      <button
        class="flex items-center justify-center rounded-md border border-gray-300 p-2 mt-5"
      >
        Search
      </button>
    </form>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import { Book } from "@/interfaces";

export default defineComponent({
  name: "TitleSearch",
  emits: ["searchCompleted", "searchErrored"],
  data() {
    return {
      searchText: ""
    };
  },
  methods: {
    searchForBook() {
      let url = `https://www.googleapis.com/books/v1/volumes?q=intitle:${this.searchText}`;
      axios
        .get(url)
        .then(r => r.data)
        .then(r => {
          if (r.totalItems === 0) {
            this.$emit("searchErrored", "Could not find any matching books.");
          } else {
            let foundBooks: Book[] = [];
            for (var i of r.items) {
              foundBooks.push({
                title: i.volumeInfo.title,
                authors:
                  i.volumeInfo.authors !== undefined
                    ? i.volumeInfo.authors.map((a: string) => {
                        return { name: a };
                      })
                    : [{ name: "Unknown" }],
                description: i.volumeInfo.description,
                thumbnailUrl: i.volumeInfo.imageLinks?.thumbnail
              });
            }
            this.$emit("searchCompleted", foundBooks);
          }
        })
        .catch(error => {
          console.error(error);
          this.$emit("searchErrored", "Could not load book info.");
        });
    }
  }
});
</script>

<style scoped>

</style>
