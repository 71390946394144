<template>
  <div>
    <div v-if="bookLoadingError">
      <h2 class="mt-5 text-lg font-semibold text-black">
        {{ bookLoadingError }}
      </h2>
      <button
        @click="loadBooks(true)"
        type="button"
        class="flex items-center justify-center rounded-md border border-gray-300 p-2"
      >
        Load books
      </button>
    </div>
    <div v-if="!bookLoadingError">
      <transition name="fade">
        <BarcodeScanner
          v-if="scannerEnabled"
          @scanned-barcode="onSannedBarcode"
          @stop-scanner="onStopScanner"
        />
      </transition>
      <div class="flex">
        <input
          type="search"
          v-model="searchText"
          @input="search"
          :disabled="booksLoading"
          placeholder="Search Grace's Books"
          class="flex-auto rounded-md"
        />
        <div
          @click="scannerEnabled = !scannerEnabled"
          :disabled="booksLoading"
          class="flex-auto ml-5"
        >
          <img src="../assets/barcode.svg" width="45" alt="Scan barcode" />
        </div>
      </div>

      <p class="mt-1 text-sm italic ml-1" :class="{ invisible: booksLoading }">
        Grace has {{ books.length }} books. {{ filteredBooks.length }}
        {{ filteredBooks.length === 1 ? "matches" : "match" }} your search.
      </p>

      <h2
        class="mt-8 text-lg font-semibold text-black"
        v-if="!booksLoading && filteredBooks.length === 0 && searchText !== ''"
      >
        Grace doesn't have that book 🎉
      </h2>

      <h2
        class="mt-8 text-lg font-semibold text-black"
        v-if="!booksLoading && filteredBooks.length === 0 && searchText === ''"
      >
        Grace doesn't have any books 😔
      </h2>

      <ul class="mt-5">
        <li
          v-for="book in filteredBooks"
          :key="book.id"
          class="block py-2 rounded-md"
        >
          <BookItem :book="book" />
        </li>
      </ul>
      <LoadingSpinner v-if="booksLoading" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { State } from "@/interfaces";
import BarcodeScanner from "./BarcodeScanner.vue";
import BookItem from "./BookItem.vue";
import LoadingSpinner from "./LoadingSpinner.vue";

export default defineComponent({
  name: "BookList",
  components: {
    BookItem,
    LoadingSpinner,
    BarcodeScanner
  },
  data() {
    return {
      searchText: "",
      scannerEnabled: false
    };
  },
  computed: mapState<State>({
    ...mapGetters(["filteredBooks"]),
    books: "books",
    booksLoading: "booksLoading",
    bookLoadingError: "bookLoadingError"
  }),
  async created() {
    this.searchText = this.$store.state.bookFilter;
    this.loadBooks();
  },
  methods: {
    loadBooks(force = false) {
      if (this.$store.state.books.length === 0 || force) {
        this.$store.dispatch("loadBooks", {
          clearBooks: true,
          next: `/api/books/?format=json`
        });
      }
    },
    search() {
      this.$store.dispatch("setBookFilter", this.searchText);
    },
    onSannedBarcode(barcode: string) {
      this.searchText = barcode;
      this.$store.dispatch("setBookFilter", this.searchText);
      this.onStopScanner();
    },
    onStopScanner() {
      this.scannerEnabled = false;
    }
  }
});
</script>

<style scoped>
#book-list {
  min-width: 310px;
}

.fade-enter-active {
  transition: opacity 0s;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.invisible {
  opacity: 0;
}
</style>
