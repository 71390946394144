
import axios from "axios";
import Cookies from "js-cookie";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { Book, State } from "@/interfaces";
import BarcodeScanner from "./BarcodeScanner.vue";
import BookItem from "./BookItem.vue";
import TitleSearch from "./TitleSearch.vue";

interface AddBookData {
  scannerEnabled: boolean;
  title: string;
  authors: string;
  isbn: string;
  thumbnailUrl: string;
  description: string;
  foundBooks: Book[];
  errorMessage: string;
  successMessage: string;
  showAddModal: boolean;
}

export default defineComponent({
  name: "BookList",
  components: {
    BarcodeScanner,
    BookItem,
    TitleSearch
  },
  mounted: function() {
    // Set focus on modal so we can listen for esc key
    let addBookModal = this.$refs.addBookModal as HTMLElement;
    addBookModal.focus();
  },
  data() {
    return {
      addBookData: {
        scannerEnabled: false,
        title: "",
        authors: "",
        isbn: "",
        thumbnailUrl: "",
        description: "",
        foundBooks: [],
        errorMessage: "",
        successMessage: "",
        showAddModal: false
      } as AddBookData
    };
  },
  computed: mapState<State>({
    authenticated: "authenticated"
  }),
  methods: {
    showAddModal() {
      this.addBookData.showAddModal = true;
    },
    titleSearchCompleted(foundBooks: Book[]) {
      this.clearMessages();
      this.addBookData.foundBooks = foundBooks;
      this.showAddModal();
    },
    titleSearchErrored(errorMessage: string) {
      this.clearMessages();
      this.addBookData.errorMessage = errorMessage;
    },
    clearMessages() {
      this.addBookData.errorMessage = "";
      this.addBookData.successMessage = "";
    },
    showScanner() {
      this.addBookData.scannerEnabled = true;
      this.clearMessages();
    },
    closeAddModal() {
      let addBookModal = this.$refs.addBookModal as HTMLElement;
      addBookModal.scrollTo(0, 0);
      this.addBookData.showAddModal = false;
      this.clearMessages();
    },
    onSannedBarcode(barcode: string) {
      this.clearMessages();
      let url = `https://www.googleapis.com/books/v1/volumes?q=isbn:${barcode}`;
      axios
        .get(url)
        .then(r => r.data)
        .then(r => {
          if (r.totalItems === 0) {
            this.addBookData.errorMessage =
              "Could not find any matching books.";
            this.addBookData.foundBooks = [];
          } else {
            this.addBookData.foundBooks = [];
            for (var i of r.items) {
              this.addBookData.foundBooks.push({
                title: i.volumeInfo.title,
                authors:
                  i.volumeInfo.authors !== undefined
                    ? i.volumeInfo.authors.map((a: string) => {
                        return { name: a };
                      })
                    : [{ name: "Unknown" }],
                description: i.volumeInfo.description,
                isbn: barcode,
                thumbnailUrl: i.volumeInfo.imageLinks?.thumbnail
              });
            }
          }
          this.showAddModal();
        })
        .catch(error => {
          console.log(error);
          this.addBookData.errorMessage = "Could not load book info";
          this.showAddModal();
        });
    },
    onStopScanner() {
      this.addBookData.scannerEnabled = false;
    },
    clearForm() {
      this.addBookData.title = "";
      this.addBookData.description = "";
      this.addBookData.isbn = "";
      this.addBookData.thumbnailUrl = "";
      this.addBookData.authors = "";
    },
    async manualAddBook() {
      let result = await this.addBook({
        title: this.addBookData.title,
        description: this.addBookData.description,
        isbn: this.addBookData.isbn,
        thumbnailUrl: this.addBookData.thumbnailUrl,
        authors: this.addBookData.authors.split(",").map(a => {
          return { name: a };
        })
      });

      if (result) {
        this.clearForm();
      }
    },
    async scanAddBook(book: Book) {
      await this.addBook(book);
    },
    async addBook(book: Book): Promise<boolean> {
      try {
        await axios.post("/api/books/", book, {
          withCredentials: true,
          headers: {
            "X-CSRFTOKEN": Cookies.get("csrftoken"),
            "Content-Type": "application/json"
          }
        });
        this.clearMessages();
        this.$store.dispatch("addBook", book);
        this.addBookData.successMessage = "Book added 💫";
        return true;
      } catch (error) {
        this.clearMessages();
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.detail) {
              this.addBookData.errorMessage = `Could not add book: ${error.response.data.detail}`;
            }
            if (error.response.data.nonFieldErrors) {
              this.addBookData.errorMessage = `Could not add book: Book already added`;
            }
          } else {
            this.addBookData.errorMessage = `Could not add book: ${error.message}`;
          }
        } else {
          this.addBookData.errorMessage = "Could not add book";
        }
        return false;
      }
    }
  }
});
