
import axios from "axios";
import { defineComponent } from "vue";
import { Book } from "@/interfaces";

export default defineComponent({
  name: "TitleSearch",
  emits: ["searchCompleted", "searchErrored"],
  data() {
    return {
      searchText: ""
    };
  },
  methods: {
    searchForBook() {
      let url = `https://www.googleapis.com/books/v1/volumes?q=intitle:${this.searchText}`;
      axios
        .get(url)
        .then(r => r.data)
        .then(r => {
          if (r.totalItems === 0) {
            this.$emit("searchErrored", "Could not find any matching books.");
          } else {
            let foundBooks: Book[] = [];
            for (var i of r.items) {
              foundBooks.push({
                title: i.volumeInfo.title,
                authors:
                  i.volumeInfo.authors !== undefined
                    ? i.volumeInfo.authors.map((a: string) => {
                        return { name: a };
                      })
                    : [{ name: "Unknown" }],
                description: i.volumeInfo.description,
                thumbnailUrl: i.volumeInfo.imageLinks?.thumbnail
              });
            }
            this.$emit("searchCompleted", foundBooks);
          }
        })
        .catch(error => {
          console.error(error);
          this.$emit("searchErrored", "Could not load book info.");
        });
    }
  }
});
